body {
  padding-bottom: 20px;
  background-color: white !important;
  overflow-x: hidden;
  // ignore the text transform for placeholder text for all devices/browsers:
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }

  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }

  input::placeholder {
    /* Recent browsers */
    text-transform: none;
  }

  table tbody tr.celldata {
    text-align: left;
  }

  .outlineMapLabel {
    text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
      -1px -1px 0 #fff;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // since core-ui removal have to add these back in
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
}
