$primary: #0177c1; // #007bff;
$secondary: #6c757d;
$buttonBackground: #b6d6eb;
$buttonBackgroundHover: #307aab;

.btn,
label.btn {
    font-variant: small-caps;
    min-width: 12rem;

    @media (max-width: 768px) {
        // smaller screens
        min-width: 6rem;
    }
}
